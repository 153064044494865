/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import url from 'url'
import Cookies from 'js-cookie'
import lodashPkg, {sortBy} from 'lodash'
import {ErrorMessages} from './messages'

const authorization = {token: null}


function ArrayEnumerator(roles, index) {
  this.roles = roles || [];
  this.index = index || 0;
  this.value = (this.roles && this.roles.length >= 0 && this.index >= 0 && this.index < this.roles.length) ? this.roles[this.index] : undefined;
  this.EOF = this.index - this.roles.length >= 0;

  this.moveNext = function moveNext() {
    if(!this.EOF) {

      this.index += 1
      this.EOF = this.index - this.roles.length >= 0
      if(!this.EOF)
        this.value = this.roles[this.index]
      else
        this.value = undefined
    }

  }
}

const {uniq, union, } = lodashPkg

const environment =
  typeof window !== 'undefined' && window !== undefined && window._env !== undefined
    ? window._env
    : null



const getApiHost = () => {
  return environment && environment.REACT_APP_UserManagerBaseUrl && environment.REACT_APP_BaseUrl && (environment.REACT_APP_UserManagerBaseUrl.trim('/') !== environment.REACT_APP_BaseUrl.trim('/'))
    ? environment.REACT_APP_UserManagerBaseUrl
    : environment.REACT_APP_BaseUrl
}

const getApiHost2 = (apiBaseUrlName) => {
  const apiBaseUrl = environment[apiBaseUrlName]
  return environment && apiBaseUrl && environment.REACT_APP_BaseUrl && (apiBaseUrl.trim('/') !== environment.REACT_APP_BaseUrl.trim('/'))
    ? apiBaseUrl
    : environment.REACT_APP_BaseUrl
}

const getUserManagerBaseUrl = () => {
  if(environment && typeof window !== 'undefined') {
    return `${getApiHost()}${environment.REACT_APP_UserManagerPath}`
  }
  return ''
}

const getTransactionMiddlewareBaseUrl = () => {
  if(environment && typeof window !== 'undefined') {
    return `${getApiHost2('REACT_APP_TransactionMiddlewareBaseUrl')}${environment.REACT_APP_TransactionMiddlewarePath}`.replace(/([^:])\/\//, '$1/')
  }
  return ''
}

const getAtmProjectBaseUrl = () => {
  if(environment && typeof window !== 'undefined') {
    return `${getApiHost2('REACT_APP_AtmProjectsBaseUrl')}${environment.REACT_APP_AtmProjectsPath}`.replace(/([^:])\/\//, '$1/')
  }
  return ''
}

const getATMVideoBaseUrl = () => {
  if(environment && typeof window !== 'undefined') {
    return `${getApiHost2('REACT_APP_AtmVideoBaseUrl')}${environment.REACT_APP_AtmVideoPath}`
  }
  return ''
}


const getFeaturesBaseUrl = () => {
  if(environment && typeof window !== 'undefined') {
    return `${getApiHost2('REACT_APP_FeaturesBaseUrl')}${environment.REACT_APP_FeaturesPath}`
  }
  return ''
}

const getTrakkerUserguideUrl = () => {
  if(environment && typeof window !== 'undefined') {
    return `${environment.REACT_APP_BaseUrl}/docs/ATMConnect_Trakker_User_Guide.pdf`
  }
  return ''
}

const getTrakkerFIUserguideUrl = () => {
  if(environment && typeof window !== 'undefined') {
    return `${environment.REACT_APP_BaseUrl}/docs/ATMConnect_TrakkerFI_User_Guide.pdf`
  }
  return ''
}

const getATMAccessMgmtUserguideUrl = () => {
  if(environment && typeof window !== 'undefined') {
    return `${environment.REACT_APP_BaseUrl}/docs/ATMConnect_ATMAccessMgmt_User_Guide.pdf`
  }
  return ''
}

/**
 * Determines if user in external or internal
 * Returns true for external user
 */
export const isLoginEnabled = () => {
  if(environment) {
    return environment.REACT_APP_LoginEnabled.toLowerCase() === 'true'
  }
  return true
}

export const isTrakkerNavigationEnabled = () => {
  if(environment && typeof window !== 'undefined') {
    if(!!environment.REACT_APP_ENABLE_TRAKKER_AUTO_NAVIGATION)
      return environment.REACT_APP_ENABLE_TRAKKER_AUTO_NAVIGATION.toLowerCase() === 'true'
  }
  return true
}

const getAuthenticateBaseUrl = () => {
  if(environment && typeof window !== 'undefined') {
    return `${getApiHost()}${environment.REACT_APP_AuthenticatePath}`.replace(/([^:])\/\//, '$1/')
  }
  return ''
}

export const apiURLs = {
  baseUrl: environment !== null ? environment.REACT_APP_BaseUrl : '',
  userManagerBaseUrl: getUserManagerBaseUrl(),
  authenticateUrl: getAuthenticateBaseUrl(),
  forgeRockAuthenticate: environment !== null ? environment.REACT_APP_ForgeRockAuthenticateUrl : '',
  transactionMiddlewareBaseUrl: getTransactionMiddlewareBaseUrl(),
  atmProjectBaseUrl: getAtmProjectBaseUrl(),
  atmvideoUrl: getATMVideoBaseUrl(),
  featuresBaseUrl: getFeaturesBaseUrl()
}

export const externalApplicationUrls = {
  trakker: environment !== null ? environment.REACT_APP_TrakkerURL : '',
  trakkerNew: environment !== null ? environment.REACT_APP_TrakkerURL_NEW : '',
}

export const userGuideUrls = {
  trakker: getTrakkerUserguideUrl(),
  trakkerFI: getTrakkerFIUserguideUrl(),
  atmAccessMgmt: getATMAccessMgmtUserguideUrl()
}

export const getWithCredentials = () => {
  return true;
}

/**
 * Returns page title
 * @param {string} route - route prop from next link
 */
export const getPageTitle = (route = '') => {
  switch(route.toLowerCase()) {
    case '/userprofile':
      return 'My Profile'
    case '/companymanager':
      return 'Company Manager'
    case '/usermanager':
      return 'User Manager'
    case '/reports':
      return 'Reports'
    case '/atmproject':
      return 'Workstation Manager'
    case '/tmsyncdata':
        return 'TM Data Sync'  
    case '/tmadhoc':
          return 'TM AdHoc'      
    case '/atmvideo':
      return 'Cash Vendor Training Videos'

    case '/contactus':
      return 'Contact Us'
    case '/help':
      return 'Help'
    case '/privacy':
      return 'Privacy & Security'
    case '/login':
      return 'Login'
    default:
      return ''
  }
}

/**
 * Get's the status
 */
export const getApplicationIds = () => {
  const userStatusValues = [
    {label: 'ATM Access Management', value: 'ATMAccessManagement'},
    {label: 'Trakker', value: 'Trakker'},
    {label: 'Trakker Financial Institution', value: 'TrakkerFI'},
  ]
  return userStatusValues
}

/**
 * Get's the status
 */
export const getApplicationIdsFilter = () => {
  // const userStatusValues = [{ label: ' ', value: ' ' }, ...getApplicationIds()]
  const userStatusValues = [...getApplicationIds()]
  return userStatusValues
}

/**
 * Checks for null and undefined and returns trimmed string
 * @param {string} val - string value
 */
export const isNullOrEmpty = val => {
  if(!val) return ''
  if(Array.isArray(val)) return val
  return typeof val.valueOf() === 'string' ? val.trim() : val
}

/**
 * Merges updated properties with old object, while preserving old values
 * @param {object} oldObject
 * @param {object} updatedProperties
 */
export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  }
}

export const setCookie = (name, value, expirationMinutes, path, domain, secure, samesite) => {
  // let expiryDate = ''
  // if (expirationMinutes) {
  //   const date = new Date()
  //   date.setTime(date.getTime() + 15 * 60 * 1000)
  //   expiryDate = `; expiryDate=" ${date.toUTCString()}`
  // }

  // (expiryDate === '' ? '' : '; expires=' + expiryDate) +
  const cookie = `${name}=${escape(value)}${path == null ? '' : `; path=${path}`}${domain == null ? '' : `; domain=${domain}`
    }${secure == null ? '' : '; secure'}${samesite == null ? '' : `; samesite=${samesite}`}`

  document.cookie = cookie
  // eslint-disable-next-line no-console
  // console.log(cookie)
}

/**
 * Checks if the given roles has connect role
 * @param {*} securityEquals - Roles
 * @param {*} delimiter  - string delimiter
 */
export const hasConnectRole = (securityEquals, delimiter) => {
  const str = isNullOrEmpty(securityEquals)
  if(!str) return false
  const arr = str.indexOf(delimiter) > -1 ? str.split(delimiter) : str
  if(arr.length > 0) {
    const connectRole = arr.find(o => o.split(',')[0].replace('cn=', '').startsWith('ATM_Conn_User'))
    if(isNullOrEmpty(connectRole) !== '') return true
  }
  return false
}

export const applicationIds = {
  ATMAccessManagement: 'ATMAccessManagement',
  Trakker: 'Trakker',
  TrakkerFI: 'TrakkerFI',
  Connect: 'Connect20'
}

export const userType = {
  TrakkerUser: 'TrakkerUser',
  AWSUser: 'AWSUser',
  CompanyAdmin: 'CompanyAdmin',
  InternalAdmin: 'InternalAdmin',
  TMUser: 'TMUser'
}

/**
 * Returns if user is admin
 * @param {*} userRoles
 */
export const isAdmin = userRoles => {
  return (
    userRoles &&
    (userRoles.indexOf(userType.CompanyAdmin) > -1 ||
      userRoles.indexOf(userType.InternalAdmin) > -1)
  )
}


/**
 * Returns if user is admin
 * @param {*} userRoles
 */
export const isTMUser = userRoles => {
  return (
    userRoles &&
    (userRoles.indexOf(userType.TMUser) > -1)
  )
}


/**
 * Returns logged in user roles converted to userType
 * @param {*} securityEquals - roles
 */
export const getLoggedInUserRoles = securityEquals => {
  const roles = []
  const str = isNullOrEmpty(securityEquals)
  if(str === '') return []
  const arr = str.indexOf('|') > -1 ? str.split('|') : str

  if(arr.length > 0) {
    arr.forEach(role => {
      const strRole = role

      if(!isLoginEnabled()) {
        if(strRole.includes('ATM_Connect_Admin') &&
          roles.indexOf(userType.InternalAdmin) === -1)
          roles.push(userType.InternalAdmin)

        if(strRole.includes('APP_7935_ATM_Connect_TM_User') && roles.indexOf(userType.TMUser) === -1)
          roles.push(userType.TMUser)


      }
      else {
        if(strRole.includes('SSO_Trkr_') && roles.indexOf(userType.TrakkerUser) === -1)
          roles.push(userType.TrakkerUser)
        if(strRole.includes('ATM_Conn_CompanyAdmin') && roles.indexOf(userType.CompanyAdmin) === -1)
          roles.push(userType.CompanyAdmin)
        if(strRole.includes('ATM_IAM_') && roles.indexOf(userType.AWSUser) === -1)
          roles.push(userType.AWSUser)
      }
    })
  }
  return roles
}

export const getShortRole = (role) => {
  return role ? (role.split(',')[0]
    .replace('cn=', '')) : ''
}

// const defaultUsrRole = (role) => {
//   switch (role) {
//     case 'cn=ATM_Conn_User,ou=TransactionServices,ou=Commercial,o=USBank':
//       return {
//         applicationId: 'Connect20',
//         applicationDescription: 'ATM Connect',
//         roleDescription: 'ATM Connect User',
//         roleType: 'USR',
//         c2PDString: role,
//       }
//     case 'cn=SSO_Trkr_User,ou=TransactionServices,ou=Commercial,o=USBank':
//       return {
//         applicationId: 'Trakker',
//         applicationDescription: 'Trakker',
//         roleDescription: 'Trakker User',
//         roleType: 'USR',
//         c2PDString: role,
//       }
//     default:
//       return null
//   }
// }
export const companyApplicationIds = (roles) => uniq(union(roles.map(r => {return r.applicationId;}), [applicationIds.Connect]))

export const roleSortComparer = (a, b) => {

  // raise CAD to top/root if it exists
  if(a.roleType === 'CAD')
    return -1
  if(b.roleType === 'CAD')
    return 1
  if(a.missingDefaultRole && !b.missingDefaultRole)
    return -1
  if(b.missingDefaultRole && !a.missingDefaultRole)
    return 1

  // raise all orphaned roles below CAD
  if(a.orphaned && !b.orphaned)
    return -1
  if(b.orphaned && !a.orphaned)
    return 1
  // everything else goes below CAD and Orphaned
  if(a.roleType < b.roleType)
    return -1
  if(a.roleType === b.roleType)
    return 0
  return 1

}


export const defaultUserRoles = (companyRoles) => companyRoles ? companyRoles.filter(r => r.roleType === 'USR').map(r => r.c2PDString) : []

/**
 * Converts userRoles to roles object
 * LDAP roles will not have ApplicationID, we get the matching application id from company roles
 * @param {*} companyRoles
 * @param {*} userRoles
 */
export const combineUserMangagerRoles = (companyRoles, userRoles) => {

  const getOrphanedRoleProperties = (c2PDString) => {
    return {
      applicationId: applicationIds.Connect,
      applicationDescription: 'ATM Connect',
      roleDescription: '',
      roleType: getShortRole(c2PDString),
    }
  }
  const sortedCr = sortBy(companyRoles, 'c2PDString')

  const defaultRoles = defaultUserRoles(sortedCr)
  // get default user roles from company roles
  const sortedUr = uniq(union(userRoles, defaultRoles)).sort()
  const ur = new ArrayEnumerator(sortedUr, 0)
  const cr = new ArrayEnumerator(sortedCr, 0)

  let inUserRoles = null
  const merged = []
  while(!cr.EOF || !ur.EOF) {

    if(ur.EOF || (!cr.EOF && cr.value.c2PDString < ur.value)) {
      // unassigned company role
      merged.push({
        ...cr.value,
        checked: false, isUserRole: false, orphaned: false, display: true
      })
      cr.moveNext()
    } else
      if(cr.EOF || (ur.value < cr.value.c2PDString)) {
        // user role not found in company roles, i.e. orphaned
        merged.push({
          ...getOrphanedRoleProperties(ur.value),
          checked: false, isUserRole: true, orphaned: true, display: true
        })
        ur.moveNext()
      }
      else {
        // assigned company role: cr.value.c2PDString === ur.value
        if(cr.value.roleType === 'USR' && userRoles.length > 0)
          inUserRoles = userRoles.find(r => r === cr.value.c2PDString) && true
        else
          inUserRoles = true
        const missingDefaultRole = !inUserRoles
        merged.push({
          ...cr.value,
          checked: true, isUserRole: true, orphaned: false, display: cr.value.roleType !== 'USR', missingDefaultRole
        })
        cr.moveNext()
        ur.moveNext()
      }
  }
  const sortedRoles = merged.sort(roleSortComparer)
  return sortedRoles
}
/**
 * Converts userRoles (selected roles from usermanager) to LDAP understanable roles
 * @param {*} companyRoles
 * @param {*} userRoles
 */
export const convertUserRolesToPingRoles1 = userRoles => {
  const lpdapRoles = []
  if(userRoles) {
    for(let index = 0;index < userRoles.length;index += 1) {
      const userRole = userRoles[index]
      // console.log(userRole)
      const c2PDString = userRole.split(',')[0].replace('cn=', '')
      lpdapRoles.push(c2PDString)
    }
  }
  return lpdapRoles
}


/**
 * Converts userRoles (selected roles from usermanager) to LDAP understandable roles
 * @param {*} companyRoles
 * @param {*} userRoles
 */
export const convertUserRolesToPingRoles = (roles) => {
  let newUserRoles = []
  if(roles)
    newUserRoles = roles
      .filter(r => r.checked)
      .map(r => getShortRole(r.c2PDString))
  return newUserRoles
}

/**
 * Converts companyRoles (selected roles from companymanager) to DB understandable roles
 * @param {*} companyRoles
 * @param {*} userRoles
 */
export const convertCompanyRolesToDBRoles = (applicationRoles, selectedCompanyRoles) => {
  const newCompanyRoles = []
  if(selectedCompanyRoles && applicationRoles) {
    for(let index = 0;index < applicationRoles.length;index += 1) {
      const appRole = applicationRoles[index]
      if(appRole.roleType === 'USR'
        || selectedCompanyRoles.findIndex(o => o.applicationId === appRole.applicationId && o.roleType === appRole.roleType) > -1)
        newCompanyRoles.push(appRole.c2PDString)

    }
  }


  // cn=ATM_Conn_User,ou=TransactionServices,ou=Commercial,o=USBank
  // cn=SSO_Trkr_User,ou=TransactionServices,ou=Commercial,o=USBank
  // alway add Connect default role
  // applicationRoles.filter(r => r.roleType==='USR').forEach(r => {
  //   newCompanyRoles.push(r.c2PDString)
  // });
  // newCompanyRoles.push('cn=ATM_Conn_User,ou=TransactionServices,ou=Commercial,o=USBank')

  // if trakker company, by default add SSO_Trkr_User role

  // if (applicationRoles.find(o => o.c2PDString.toLocaleLowerCase().includes('sso_trkr'))) {

  // if(applicationRoles.find(o => o.applicationId===applicationIds.Trakker||o.applicationId===applicationIds.TrakkerFI)) {
  //   newCompanyRoles.push('cn=SSO_Trkr_User,ou=TransactionServices,ou=Commercial,o=USBank')
  // }

  return newCompanyRoles
}




/**
 * Converts userType(s) to string for displaying in top white menu
 * @param {*} roles
 */
export const getFriendlyRoleNames = roles => {
  const arr = []
  if(roles && roles.length > 0) {
    roles.forEach(usertype => {
      if(usertype === userType.TrakkerUser) arr.push('Trakker User')
      else if(usertype === userType.AWSUser) arr.push('ATM User')
      else if(usertype === userType.CompanyAdmin) arr.push('Company Admin')
    })
    if(arr.length > 1) arr.sort((a, b) => a.localeCompare(b))
  }
  return arr
}


/**
 * Return response data from axios error
 * @param {*} errResponse
 */
export const getErrorResponse = errResponse => {
  let problemDetail = null

  try {
    if(errResponse && errResponse.response) {
      if(errResponse.response.status === 401)
        return ErrorMessages.notAuthenticated
      if(errResponse.response.status === 403)
        return ErrorMessages.notAuthorized
      if(errResponse.response.status >= 500)
        return ErrorMessages.genericError
      if(errResponse.response.data) {
        if(errResponse.response.data.type) {
          problemDetail = errResponse.response.data
          switch(problemDetail.type) {
            case 'https://atmconnect.usbank.com/connecterror':
              return 'Encountered an error saving changes.';
            case 'https://atmconnect.usbank.com/updateerror':
              return 'Encountered an error saving changes.';
            case 'https://atmconnect.usbank.com/currentpasswordincorrect':
              return 'Your Current Password is incorrect.';
            case 'https://atmconnect.usbank.com/newpassworderror':
              return 'New password cannot be one of the 12 most recent passwords used in the past 12 months.';
            case 'https://atmconnect.usbank.com/nochangesdetected':
              return 'No changes were detected.';
            case 'https://atmconnect.usbank.com/resourcealreadyexists':
              return 'User already exists.';
            case 'https://atmconnect.usbank.com/failedconcurrencycheck':
              return "Cannot update without most recent changes. Reload to get latest."

            default:
              return problemDetail.title;
          }
        }
        else
          if(errResponse.response.data.messages) {
            const message = errResponse.response.data.messages[0];
            if(message) {
              if(errResponse.response.status === 400) {
                if(message.toLowerCase().startsWith('current password does not match'))
                  return 'Your Current Password is incorrect.'
              }
              return message
            }
          }
      }
      if(errResponse.response.statusText)
        return errResponse.response.statusText
    } else if(errResponse && errResponse.message) {
      if(errResponse.message === 'Network Error')
        return 'Network Error'
    }

  } catch(error) {
    return 'Unknown Error'
  }
  return 'Unknown Error'
}

export const getSharedCookieDomain = () => {
  let domain = 'usbank.com'
  try {
    domain = document && document.domain ? document.domain.toLowerCase() : 'usbank.com'
    if(domain.includes('usbank.com')) domain = '.usbank.com'
    else if(domain.includes('us.bank-dns.com')) domain = '.us.bank-dns.com'
    else if(domain.includes('localhost')) domain = 'localhost'
  } catch(error) {
    domain = '.usbank.com'
  }
  return domain
}

const getDom = () => {
  return typeof window !== 'undefined'
    ? window.document
    : {URL: 'http://localhost:5000', domain: 'localhost:5000'}
}

const getEnvironmentPrefix = () => {
  const firstNode = getDom().domain.split('.')[0]
  const parsed = firstNode
    .replace('atmconnect', '')
    .replace('ext-', '')
    .replace('2', '')
  switch(parsed) {
    case '':
      return ''
    case 'localhost':
      return 'dev-'
    default:
      return parsed
  }
}

export const getIPlanetProCookieName = () => {
  switch(getEnvironmentPrefix()) {
    case '':
      return 'iPlanetDirectoryPro'
    case 'dev-':
      return 'iPlanetDirectoryProDev'
    case 'it-':
      return 'iPlanetDirectoryProIT'
    case 'uat-':
      return 'iPlanetDirectoryProUAT'
    default:
      return 'iPlanetDirectoryPro'
  }
}

export const getUserInfo = jwt => {
  return atob(jwt.split('.')[1])
}

export const initialStateJwtUserInfo = {
  unique_name: '',
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/dns': '',
  role: [],
  nbf: 0,
  exp: 0,
  iat: 0,
  iss: 'https://atmconnect.usbank.com',
  aud: 'https://atmconnect.usbank.com'
}

export const getBackgroundImageURL = imageName => {
  if(typeof window !== 'undefined') {
    let str = document.domain === 'localhost' ? 'http://localhost:5000' : apiURLs.baseUrl
    str = `url('${str}/images/${imageName}')`
    return str
  }
  return ''
}

export const getVideoURL = videoName => {
  if(typeof window !== 'undefined') {
    let str = document.domain === 'localhost' ? 'http://localhost:5000' : apiURLs.baseUrl
    str = `${str}videos/${videoName}`
    return str
  }
  return ''
}

export const getATMVideoURL = videoName => {
  if(typeof window !== 'undefined') {
    let str = document.domain === 'localhost' ? 'http://localhost:5000' : apiURLs.atmvideosUrl
    str = `${str}/${videoName}`
    return str
  }
  return ''
}

export const getInternalTMVideo = () => {
  return environment.REACT_APP_INTERNAL_video1
}

export const AddPDPCookie = instance => {
  // if we are calling a localhost API, then try to set the
  // planetprocookie header because localhost API is having
  // trouble getting the cookie from the browser because
  // localhost is not a valid cookie domain
  if(
    apiURLs.userManagerBaseUrl &&
    apiURLs.userManagerBaseUrl.length > 0 &&
    (url.parse(apiURLs.baseUrl)
      .host.toLocaleLowerCase()
      .startsWith('localhost')
      ||
      url.parse(apiURLs.baseUrl)
        .host.toLocaleLowerCase()
        .startsWith('dev-atmconnect')
    )
  ) {
    const iPDPCookieName = getIPlanetProCookieName()
    const browserCookieValue = Cookies.get(iPDPCookieName)
    if(browserCookieValue && browserCookieValue.trim().length > 0)
      // eslint-disable-next-line no-param-reassign
      instance.defaults.headers[iPDPCookieName] = browserCookieValue
  }
}

export const ScrollTop = () => {
  const control = document.getElementById('divMainLayout')
  if(control) {
    setTimeout(() => {
      control.scrollIntoView({behavior: 'smooth'})
    }, 100)
  }
}

export const isCompanyManagerEnabled = () => {
  return environment.REACT_APP_ENABLE_CompanyManager.toLowerCase() === 'true';
}

export const formatMilliseconds = (ms) => {
  return (`${Math.floor(ms / 60000)}:${Math.floor(ms % 60000 / 1000)} (mm:ss)`)
}
export const formatTime = (date) => {
  return new Intl.DateTimeFormat('en-US', {hour: '2-digit', minute: '2-digit', second: '2-digit', }).format(date)
}
export const formatInterval = (date) => {
  return new Intl.DateTimeFormat('en-US', {minute: '2-digit', second: '2-digit', }).format(date)
}

export const atmConnectVersion = process.env.APP_VERSION

/* (environment && typeof window !== 'undefined' && environment.NEXT_PUBLIC_APP_VERSION) ? environment.NEXT_PUBLIC_APP_VERSION.toUpperCase() : process.env.NEXT_PUBLIC_APP_VERSION  */

